<template>
  <v-app style="background: none">

    <!-- SELECT LIGHT/DARK THEME -->
    <v-app-bar
        app
        :dark="true"
        v-if="logged"
    >

      <!-- Logo -->
<!--      <img src="./assets/isotipo-color-w.png" height="30px">-->
<!--            <img src="./assets/partners/andel.svg" height="30px">-->
            <img src="./assets/partners/bestdrive.svg" height="60px">

      <v-spacer></v-spacer>

      <!-- Menu -->
      <div class="d-none d-lg-inline-flex">

        <v-btn
            text
            active-class="no-active"
            :color="currentPage.includes('tsb') ? 'primary' : ''"
            to="/tsb"
        >
          <i class="fad fa-book-open mr-1 fa-lg"></i> Boletines
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                active-class="no-active"
                :color="currentPage.includes('academy') ? 'primary' : ''"
                v-bind="attrs"
                v-on="on"
            >
              <i class="fad fa-graduation-cap mr-1 fa-lg"></i> FORMACIÓN
            </v-btn>
          </template>
          <v-list nav>
            <v-list-item href="/academy/system">
              <v-list-item-title>Cursos por família</v-list-item-title>
            </v-list-item>
            <v-list-item href="/academy/vehicle">
              <v-list-item-title>Cursos por vehículo</v-list-item-title>
            </v-list-item>
            <v-list-item href="/academy/management">
              <v-list-item-title>Cursos de gestión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
            text
            active-class="no-active"
            :color="currentPage.includes('procedure') ? 'primary' : ''"
            to="/procedure"
        >
          <i class="fad fa-wave-square mr-1 fa-lg"></i> Procesos
        </v-btn>

<!--        <v-btn-->
<!--            text-->
<!--            active-class="no-active"-->
<!--            :color="currentPage.includes('video') ? 'primary' : ''"-->
<!--            to="/video"-->
<!--        >-->
<!--          <i class="fad fa-video mr-1 fa-lg"></i> Video-->
<!--        </v-btn>-->

        <v-btn
            text
            active-class="no-active"
            :color="currentPage.includes('partners') ? 'primary' : ''"
            to="/partners"
        >
          <i class="fad fa-star mr-1 fa-lg"></i> Nuestras Marcas
        </v-btn>

        <v-btn
            text
            to="/"
            color="primary"
        >
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>

      </div>

      <!-- Mobile -->
      <v-app-bar-nav-icon
          @click.stop="menu = !menu"
          class="d-inline-flex d-lg-none primary--text"
      ></v-app-bar-nav-icon>

    </v-app-bar>

    <v-navigation-drawer
        app
        v-model="menu"
        temporary
        right
        dark
    >

      <v-list-item class="primary--text">
        <v-list-item-title>{{ userName }}</v-list-item-title>
        <v-btn
            icon
            to="/"
            color="primary"
        >
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider class="mt-0"></v-divider>

      <v-list class="pl-2">
        <v-list-item to="/tsb">
          <v-list-item-title><i class="fad fa-book-open mr-2 fa-lg"></i> Boletines</v-list-item-title>
        </v-list-item>
        <v-list-item to="/academy/system">
          <v-list-item-title><i class="fad fa-graduation-cap mr-2 fa-lg"></i> Cursos por sistema</v-list-item-title>
        </v-list-item>
        <v-list-item to="/academy/vehicle">
          <v-list-item-title><i class="fad fa-graduation-cap mr-2 fa-lg"></i> Cursos por vehículo</v-list-item-title>
        </v-list-item>
        <v-list-item to="/academy/management">
          <v-list-item-title><i class="fad fa-graduation-cap mr-2 fa-lg"></i> Cursos de gestión</v-list-item-title>
        </v-list-item>
        <v-list-item to="/procedure">
          <v-list-item-title><i class="fad fa-wave-square mr-2 fa-lg"></i> Procesos</v-list-item-title>
        </v-list-item>
        <v-list-item to="/video">
          <v-list-item-title><i class="fad fa-video mr-2 fa-lg"></i> Video</v-list-item-title>
        </v-list-item>
        <v-list-item to="/partners">
          <v-list-item-title><i class="fad fa-star mr-2 fa-lg"></i> Nuestras marcas</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main :class="logged ? 'mt-6' : ''">
      <router-view @login="login" @logout="logout" :logged="logged"/>

      <div v-if="logged" class="container-fluid text-center" style="bottom:0px; height:100px; position: relative;">
        <hr>
<!--        <img src="./assets/logo-color-horizontal.png" style="height:60px" alt="">-->
<!--        <img src="./assets/partners/andel.svg" height="60px">-->
        <img src="./assets/partners/bestdrive.svg" height="100px">


      </div>

    </v-main>

    <v-footer
        v-if="logged"
        class="text-center"
        dark
    >
      Powered by
      <img src="./assets/logo-next.webp" alt="">
    </v-footer>

    <div class="fixed-bottom-right">

      <v-btn
          class="black--text font-weight-bold"
          color="#EB6C16"
          tile
          small
      >
        <span>{{ version }}</span>
      </v-btn>

    </div>

  </v-app>
</template>

<script>

import settings from "./settings";
import axios from 'axios';
let API_URL = settings.API_URL;
export default {
  name: 'App',
  data() {
    return {
      logged: 1, //TODO CHANGE
      menu: false,
      phoneMenu: 0,
      userName: '',
      token: '',
      counts: '',
      version: settings.VERSION,
    }
  },
  computed: {
    currentPage() {
      return this.$route.path;
    }
  },
  methods: {
    login(user) {
      this.logged = 1;
      this.userName = user;
      this.$router.push('/tsb')
    },
    logout() {
      this.logged = 0;
      this.$router.push('/')
    },
    counters() {
      axios.get(API_URL + 'counters.php').then(response => {
        this.counts = response.data
      });
    },
  },
  created() {
    this.counters();
    this.userName = sessionStorage.username;
    this.token = sessionStorage.token;
  }
}
</script>

<style lang="scss" scoped>
@import 'theme.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
</style>

<style>

.fixed-bottom-right {
  bottom: 0px;
  right: 0px;
  position: fixed;
  z-index: 99999;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.top-row {
  background-color: white;
  box-shadow: 0 3px 5px #AAA;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.menu-enter-active {
  transition: all 0.5s ease;
}

.menu-leave-active {
  transition: all 0.5s ease;
}

.menu-enter, .menu-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.menu-enter-to, .menu-leave {
  max-height: 500px;
  overflow: hidden;
}
</style>