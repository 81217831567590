import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {

                /* SELECT PARTNER COLOR */
                // primary: '#EB6C16',     // Default
                // primary: '#e7521f',     // Andel
                primary: '#eca02d',     // BestDrive

                secondary: '#919293',
            },
        },
    },
});
