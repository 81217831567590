export default {
    VERSION: '0.1.0',
    API_URL: 'https://api.tecnoclub.org/infotechnic-next/',

    /* SELECT PARTNER */
    // PARTNER: 'next'
    // PARTNER: 'andel',
    PARTNER: 'bestdrive',

}

/* THEME SELECTOR
*  settings.js
*  theme.scss
*  router.js
*  App.vue (logo)
* */