<template>
  <div class="container-fluid">
    <h1>NUESTRAS MARCAS</h1>
  </div>
</template>

<script>
export default {
  name: "video-view",
  props: ['logged'],
  data() {
    return {

    }
  },
  methods: {

  },
  created() {
    if (this.logged == 0) {
      this.$router.push('/')
    }
  },
  watch: {}
}
</script>
